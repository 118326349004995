var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("cafe-menu", {
    attrs: {
      name: "내카페",
      "menu-list": _vm.menuList,
      "initial-index": _vm.index,
    },
    on: { select: _vm.onPageChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
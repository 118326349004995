var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { class: _vm.className }, [
    _c("a", {
      staticClass: "link_tab",
      attrs: { href: _vm.hrefLink },
      domProps: { innerHTML: _vm._s(_vm.name) },
      on: { click: _vm.onClick },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
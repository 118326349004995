var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "list_tab" },
    _vm._l(_vm.menuList, function (menu, i) {
      return _c("cafe-sub-menu-item", {
        key: i,
        attrs: { menu: menu, "selected-menu": _vm.menuList[_vm.selected] },
        on: { select: _vm.onMenuSelect },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
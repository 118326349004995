var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "thumb_info_type",
      attrs: { href: _vm.item.link, target: "_blank" },
    },
    [
      _c("div", { staticClass: "wrap_info" }, [
        _c("div", { staticClass: "box_tbl" }, [
          _c("div", { staticClass: "inner_tbl" }, [
            _c("strong", { staticClass: "tit_info" }, [
              _vm._v(_vm._s(_vm.item.cafeName)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "additional_info" }, [
              _c("span", { staticClass: "txt_date" }, [
                _vm._v("탈퇴일  " + _vm._s(_vm.convertedDate)),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { class: _vm.className }, [
    _c("a", { staticClass: "link_opt", on: { click: _vm.onClick } }, [
      _vm._v(_vm._s(_vm.name)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
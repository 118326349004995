export var AUTH_TYPE = {
    /** 설정 안 됨 */
    NONE: 'NONE',
    /** 휴대폰 본인인증 */
    SCI: 'SCI',
    /** 비밀번호 본인인증 */
    PASSWORD: 'PASSWORD',
};
export var ACCOUNT_TYPE = {
    /** 개인 */
    INDIVIDUAL: 'individual',
    /** 사업자 */
    CORPORATION: 'corporation',
};
export var PROFIT_ERROR_NAME = 'PROFIT_ERROR';
export var PROFIT_ERROR_CODE = {
    BEFORE_CHANGEABLE_DATE: 1,
    INVALID_BANK_ACCOUNT: 2,
    NOT_FOUND: 3,
    ALREADY_EXIST: 4,
    INPROGRESS: 5,
    NEED_EMAIL: 6,
    UNAUTHORIZED: 13,
};
export var HTTP_STATUS_CODE = {
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    INTERNAL_SERVER_ERROR: 500,
};
export var COMMON_SETTLEMENT_STATUS = {
    /** 정산 진행 현황, 지급 내역이 없는 상태 */
    INIT: 'INIT',
    /** 정산 내역 없음 */
    NONE: 'NONE',
    /** 정산 이월 */
    CARRYOVER: 'CARRYOVER',
    /** 정산 일시 중지 */
    TEMPORARY_PAUSE: 'TEMPORARY_PAUSE',
};
export var INDIVIDUAL_SETTLEMENT_STATUS = {
    /** 확인 */
    CHECK: 'CHECK',
    /** 지급중 */
    PROGRESS: 'PROGRESS',
    /** 완료 */
    COMPLETE: 'COMPLETE',
};
export var CORPORATION_SETTLEMENT_STATUS = {
    /** 세금 계산서 자동 신청 */
    AUTOMATIC_APPLICATION: 'AUTOMATIC_APPLICATION',
    /** 세금 계산서 서명 요청 */
    SIGNATURE_REQUEST: 'SIGNATURE_REQUEST',
    /** 지급중 */
    PROGRESS: 'PROGRESS',
    /** 완료 */
    COMPLETE: 'COMPLETE',
};

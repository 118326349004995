var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { ACCOUNT_TYPE, PROFIT_ERROR_NAME, HTTP_STATUS_CODE } from './constants';
var instance = axios.create({
    params: {
        grpid: window.GRPID,
    },
});
function isProfitApiError(e) {
    var _a, _b;
    var profitApiError = e;
    var httpStatusCode = (_a = profitApiError === null || profitApiError === void 0 ? void 0 : profitApiError.response) === null || _a === void 0 ? void 0 : _a.status;
    var isServerError = !httpStatusCode || httpStatusCode >= HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR;
    var hasProfitError = !!((_b = profitApiError === null || profitApiError === void 0 ? void 0 : profitApiError.response) === null || _b === void 0 ? void 0 : _b.data);
    return !isServerError && hasProfitError;
}
function makeProfitError(profitApiError) {
    return {
        name: PROFIT_ERROR_NAME,
        httpStatusCode: profitApiError.response.status,
        code: profitApiError.response.data.code,
        message: profitApiError.response.data.message,
    };
}
function handleGlobalError(e) {
    var _a = e.response, status = _a.status, data = _a.data;
    var code = data.code, message = data.message;
    if (status === HTTP_STATUS_CODE.FORBIDDEN && code === 13) {
        alert(message);
    }
    if (status === HTTP_STATUS_CODE.BAD_REQUEST && code === 0) {
        alert('해당 카페가 없습니다.');
    }
    if (status === HTTP_STATUS_CODE.BAD_REQUEST && code === 12) {
        alert('로그인이 필요합니다.');
    }
}
/** 핸들링 하지 않는 에러를 처리한다. */
function fallbackHandleError(e) {
    console.error(e);
    alert('오류가 발생하였습니다.');
}
/**
 * 새로운 알림이 있는지 조회한다.
 */
export function checkForNewsUpdate() {
    return __awaiter(this, void 0, void 0, function () {
        var data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get('/_c21_/api/v3/my-news/total/has-new')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.hasNew];
                case 2:
                    e_1 = _a.sent();
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * 새로운 쪽지가 있는지 조회한다.
 */
export function checkForMessageUpdate() {
    return __awaiter(this, void 0, void 0, function () {
        var data, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get('/_c21_/api/v3/msgbox/total/has-new')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.hasNew];
                case 2:
                    e_2 = _a.sent();
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * 본인인증 여부, 인증 세팅 정보를 조회한다.
 */
export function fetchAuthStatus() {
    return __awaiter(this, void 0, void 0, function () {
        var data, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get("/_c21_/profit/auth?grpid=".concat(window.GRPID))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    e_3 = _a.sent();
                    if (isProfitApiError(e_3)) {
                        handleGlobalError(e_3);
                    }
                    throw e_3;
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function fetchVoninToken() {
    return __awaiter(this, void 0, void 0, function () {
        var data, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.post('/_c21_/profit/vonin/session', {
                            grpid: window.GRPID,
                            callbackUrl: window.location.origin + '/_c21_/profit/auth_callback',
                        })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    e_4 = _a.sent();
                    if (isProfitApiError(e_4)) {
                        handleGlobalError(e_4);
                    }
                    throw e_4;
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function validateVoninToken() {
    return __awaiter(this, void 0, void 0, function () {
        var data, e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.post('/_c21_/profit/vonin/session/complete', {
                            grpid: window.GRPID,
                        })];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    e_5 = _a.sent();
                    if (isProfitApiError(e_5)) {
                        handleGlobalError(e_5);
                    }
                    throw e_5;
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * 수익 카페 목록을 조회한다.
 */
export function fetchProfitCafeList() {
    return __awaiter(this, void 0, void 0, function () {
        var data, e_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get("/_c21_/profit/cafes")];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
                case 2:
                    e_6 = _a.sent();
                    if (isProfitApiError(e_6)) {
                        handleGlobalError(e_6);
                    }
                    throw e_6;
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function fetchCpInfo(isMasking) {
    if (isMasking === void 0) { isMasking = true; }
    return __awaiter(this, void 0, void 0, function () {
        var data, e_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, instance.get("/_c21_/profit/management/cp?isMasking=".concat(isMasking))];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, {
                            accountType: data.accountType,
                            individualCpInfo: data.individualCpInfo
                                ? {
                                    name: data.individualCpInfo.name,
                                    phoneNumber: data.individualCpInfo.phoneNumber,
                                    email: data.individualCpInfo.email,
                                    ssn: data.individualCpInfo.ssn,
                                    addressInfo: {
                                        zipcode: data.individualCpInfo.zipcode,
                                        address: data.individualCpInfo.address,
                                        addressDetail: data.individualCpInfo.addressDetail,
                                    },
                                    bankAccountInfo: data.bankAccountInfo,
                                }
                                : undefined,
                            corporationCpInfo: data.corporationCpInfo
                                ? {
                                    name: data.corporationCpInfo.cpName,
                                    registrationNumber: data.corporationCpInfo.bizNo,
                                    representativeName: data.corporationCpInfo.bizRepName,
                                    industry: data.corporationCpInfo.bizIndustry,
                                    sector: data.corporationCpInfo.bizSector,
                                    managerInfo: data.corporationCpInfo.managerInfo,
                                    addressInfo: {
                                        zipcode: data.corporationCpInfo.bizZipcode,
                                        address: data.corporationCpInfo.address,
                                        addressDetail: data.corporationCpInfo.addressDetail,
                                    },
                                    bankAccountInfo: data.bankAccountInfo,
                                }
                                : undefined,
                        }];
                case 2:
                    e_7 = _a.sent();
                    if (isProfitApiError(e_7)) {
                        throw makeProfitError(e_7);
                    }
                    fallbackHandleError(e_7);
                    throw e_7;
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * 당월 정산 진행 현황을 조회한다.
 */
export function fetchSettlement() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, instance.get('/_c21_/profit/management/settlement/status')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
/** CI 정보를 조회한다. */
export function fetchCiInfo() {
    return __awaiter(this, void 0, void 0, function () {
        var data, userAccountInfo, accountType, email;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, instance.get('/_c21_/profit/management/ci')];
                case 1:
                    data = (_a.sent()).data;
                    userAccountInfo = data.userAccountInfo;
                    accountType = userAccountInfo.accountType, email = userAccountInfo.email;
                    return [2 /*return*/, {
                            accountType: accountType,
                            individualCiInfo: data.individualCiInfo
                                ? __assign({ email: email }, data.individualCiInfo) : undefined,
                            corporationCiInfo: data.corporationCiInfo
                                ? __assign({ email: email }, data.corporationCiInfo) : undefined,
                        }];
            }
        });
    });
}
/** 계좌 등록이 가능한 은행 목록을 조회한다. */
export function fetchBanks() {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, instance.get('/_c21_/profit/management/account/bankcode')];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.bankCodes.map(function (_a) {
                            var bankCode = _a.bankCode, name = _a.name;
                            return ({
                                code: bankCode,
                                name: name,
                            });
                        })];
            }
        });
    });
}
/** 계좌를 인증한다. */
export function authBankAccount(bankAccountInfo) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, instance.post('/_c21_/profit/management/account/auth', bankAccountInfo)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data.result === 'SUCCESS'];
            }
        });
    });
}
/** CP 정보를 등록한다. */
export function registerCpInfo(accountType, cpInfo) {
    return __awaiter(this, void 0, void 0, function () {
        var userInfo, addressInfo, bankAccountInfo, userInfoParams, e_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    userInfo = cpInfo.userInfo, addressInfo = cpInfo.addressInfo, bankAccountInfo = cpInfo.bankAccountInfo;
                    userInfoParams = accountType === ACCOUNT_TYPE.INDIVIDUAL
                        ? {
                            cpBizNo: userInfo.ssn,
                        }
                        : {
                            bizIndustry: userInfo.industry,
                            bizSector: userInfo.sector,
                        };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, instance.post('/_c21_/profit/management/cp', __assign(__assign(__assign({ accountType: accountType }, bankAccountInfo), addressInfo), userInfoParams))];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_8 = _a.sent();
                    if (isProfitApiError(e_8)) {
                        throw makeProfitError(e_8);
                    }
                    fallbackHandleError(e_8);
                    throw e_8;
                case 4: return [2 /*return*/];
            }
        });
    });
}
/** CP 정보를 수정한다. */
export function modifyCpInfo(accountType, cpInfo) {
    return __awaiter(this, void 0, void 0, function () {
        var userInfo, addressInfo, bankAccountInfo, userInfoParams, e_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    userInfo = cpInfo.userInfo, addressInfo = cpInfo.addressInfo, bankAccountInfo = cpInfo.bankAccountInfo;
                    userInfoParams = accountType === ACCOUNT_TYPE.INDIVIDUAL
                        ? {
                            cpBizNo: userInfo.ssn,
                        }
                        : {
                            bizIndustry: userInfo.industry,
                            bizSector: userInfo.sector,
                        };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, instance.patch('/_c21_/profit/management/cp', __assign(__assign(__assign({ accountType: accountType }, bankAccountInfo), addressInfo), userInfoParams))];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_9 = _a.sent();
                    if (isProfitApiError(e_9)) {
                        throw makeProfitError(e_9);
                    }
                    fallbackHandleError(e_9);
                    throw e_9;
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function fetchPayment(year) {
    return __awaiter(this, void 0, void 0, function () {
        var data_1, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!year) return [3 /*break*/, 2];
                    return [4 /*yield*/, instance.get("/_c21_/profit/payment?year=".concat(year))];
                case 1:
                    data_1 = (_a.sent()).data;
                    return [2 /*return*/, data_1];
                case 2: return [4 /*yield*/, instance.get("/_c21_/profit/payment/all")];
                case 3:
                    data = (_a.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}

<template>
	<cafe-menu
		:name="'내카페'"
		:menu-list="menuList"
		:initial-index="index"
		@select="onPageChange"></cafe-menu>
</template>

<script>
import CafeMenu from './CafeMenu.vue';

import AjaxCaller from '../../ajax-caller';

/**
 * 내카페 메뉴바 전용 컴포넌트
 *
 * @property {string} selected	- 선택된 메뉴
 */
export default {
	components: {
		CafeMenu
	},

	props: ['selected'],
	data() {
		return {
			menuList: [{
				name: '내 카페 목록',
				href: '/_c21_/my_cafe'
			}, {
				name: '북마크',
				href: '/_c21_/my_bookmark'
			}, {
				name: '탈퇴 카페 관리',
				href: '/_c21_/exit_cafe'
			}, {
				name: '내 글 백업',
				href: '/_c21_/backup'
			}, {
				name: '알림',
				href: '/_c21_/my_news',
				isSub: true,
				isNew: false
			}, {
				name: '쪽지',
				href: '/_c21_/my_message',
				isSub: true,
				isNew: false
			}]
		};
	},

	computed: {
		index() {
			return this.menuList.findIndex(v => v.name === this.selected);
		}
	},

	methods: {
		onPageChange(index) {
			location.href = this.menuList[index].href;
		}
	},

	async created() {
		if (window.IS_PROFIT_MENU_SHOW) {
			this.menuList.push({
				name: '카페 수익 관리',
				href: '/_c21_/profit'
			})
		}

		const PREFIX = '/_c21_/api/v3';
		const NEWS_INDEX = 4;
		const MSG_INDEX = 5;

		this.menuList[NEWS_INDEX].isNew = (await AjaxCaller.get(`${PREFIX}/my-news/total/has-new`)).hasNew;
		this.menuList[MSG_INDEX].isNew = (await AjaxCaller.get(`${PREFIX}/msgbox/total/has-new`)).hasNew;
	}
}
</script>

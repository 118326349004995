<template>
	<ul class="list_tab">
		<cafe-sub-menu-item v-for="(menu, i) in menuList" :key="i"
			:menu="menu"
			:selected-menu="menuList[selected]"
			@select="onMenuSelect"></cafe-sub-menu-item>
	</ul>
</template>

<script>
import CafeSubMenuItem from './CafeSubMenuItem.vue';

/**
 * 카페 서브 메뉴
 *
 * @property {array} menuList			- 메뉴 리스트
 * @property {boolean} watchMenu		- 메뉴 리스트 변경 감지 여부
 * @property {integer} initialIndex=-1	- 초기 선택된 인덱스 값
 *
 * @fires #select						- 메뉴 선택 시 이벤트 발생
 */
export default {
	components: {
		CafeSubMenuItem
	},

	props: {
		menuList: Array,
		watchMenu: {
			type: Boolean,
			default: false
		},
		initialIndex: {
			type: Number,
			default: -1
		}
	},
	data() {
		return {
			selected: 0
		}
	},

	watch: {
		menuList() {
			if (!this.watchMenu) return;
			this.selected = 0;
			this.$emit('select', 0);
		},
		initialIndex() {
			// FIXME: ajax 때문에 초기값이 바뀌어서 넣은 비상용 코드. 더 좋은 방법이 있을지 찾아볼 것.
			this.selected = this.initialIndex;
		}
	},

	methods: {
		onMenuSelect(menu) {
			this.selected = this.menuList.indexOf(menu);
			this.$emit('select', this.selected);
		}
	},

	created() {
		if (this.menuList === undefined)
			throw new Error('`menuList` is undefined');
		if (this.menuList.length <= this.initialIndex)
			throw new Error('`initialIndex` cannot be greater than or equal to the length of `menuList`');

		this.selected = this.initialIndex === -1 ? 0 : this.initialIndex;
	}
}
</script>

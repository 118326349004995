<template>
	<div :id="`dropdown-${name}`" class="cafe_dropdown" :class="dropdownClass" v-dropdown="close">
		<div class="opt_selected" @click="onToggle">
			<a class="tf_opt">
				<slot>
					<span class="txt_selected">{{ options[selected] }}</span>
					<span class="ico_cafetop ico_arrow">옵션 더 보기</span>
				</slot>
			</a>
		</div>
		<div class="opt_layer">
			<ul class="list_opt">
				<dropdown-item v-for="option, i in options" :key="i"
					:name="option" :selected="showSelectedStyle && i === selected"
					@select="onSelect"></dropdown-item>
			</ul>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import $ from 'jquery';

import LayerController from './layer-controller';
import DropdownItem from './DropdownItem.vue';

Vue.use(LayerController);

/**
 * 드롭다운
 *
 * @property {string} name						- 해당 드롭다운 컴포넌트의 이름
 * @property {array} options					- 옵션 리스트
 * @property {boolean} showBorder=false			- border 보이기
 * @property {boolean} showSelectedStyle=true	- 선택된 아이템 강조 표시
 *
 * @fires #select								- 옵션 선택 시 이벤트 발생
 */
export default {
	components: {
		DropdownItem
	},

	props: {
		name: String,
		options: Array,
		showBorder: {
			type: Boolean,
			default: false
		},
		showSelectedStyle: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			open: false,
			selected: 0
		};
	},
	computed: {
		dropdownClass() {
			return {
				opt_open: this.open,
				opt_g: !this.showBorder,
				opt_g3: this.showBorder
			};
		}
	},

	methods: {
		onToggle() {
			this.open = !this.open;
		},
		onSelect(option) {
			this.selected = this.options.indexOf(option);
			this.open = false;
			this.$emit('select', { name: this.name, selected: this.selected });
		},
		close() {
			this.open = false;
		}
	},

	created() {
		// FIXME: 팬카페/우수카페 Vue로 교체 시 알고리즘 수정 요망
		if (this.name === undefined)
			throw new Error('`name` is undefined');
		if (this.name === '')
			throw new Error('`name` is empty string');

		$('body').on('click', (e) => {
			if ($(e.target).closest('.cafe_dropdown').length === 0) {
				this.open = false;
			}
		});
	}
}
</script>

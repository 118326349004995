<template>
	<li :class="className"><a class="link_tab" @click="onClick" v-html="name" :href="hrefLink"></a></li>
</template>

<script>
/**
 * 카페 서브 메뉴 아이템
 *
 * @property {string | object} menu - 메뉴
 * @property {string | object} selectedMenu - 전역에서 선택된 메뉴
 * @property {string} menu.name - 메뉴가 object 인 경우 메뉴 이름
 * @property {string?} menu.link - 링크
 *
 * @fires #select					- 메뉴 선택 시 이벤트 발생
 */
export default {
	props: ['menu', 'selectedMenu'],
	computed: {
		className() {
			return { on: this.menu === this.selectedMenu };
		},
		hrefLink() {
			if (typeof this.menu !== "string" && this.menu.link) {
				return this.menu.link;
			}
			return 'javascript:;';
		},
		name() {
			if (typeof this.menu === "string") {
				return this.menu;
			}
			return this.menu.name;
		}
	},

	methods: {
		onClick() {
			this.$emit('select', this.menu);
		}
	}
}
</script>

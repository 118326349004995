var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cont_mycafe" },
    [
      _c("h4", { staticClass: "screen_out" }, [_vm._v(_vm._s(_vm.name))]),
      _vm._v(" "),
      _c("div", { staticClass: "box_util" }, [_vm._t("header")], 2),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "list_mycafe", class: _vm.listClass },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm._t("footer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import CafeMenu from './CafeMenu.vue';
import CafeSubMenu from './CafeSubMenu.vue';

import CafeTable from './CafeTable.vue';
import Dropdown from './Dropdown.vue';

import MyCafeMenu from './MyCafeMenu.vue';

export default {
	install(Vue, options) {
		Vue.mixin({
			components: {
				CafeMenu, CafeSubMenu,
				CafeTable, Dropdown,
				MyCafeMenu
			}
		});
	}
}

import $ from 'jquery';

export const HTTP_STATUS = {
	OK: 200,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403
};

const ERROR_CODE = {
	NEED_TO_LOGIN: 1,
	INVALID_REQUEST_PARAMETER: 2,
	MAX_FAVORITE_CAFE_COUNT_OVER: 3,
	MAX_CAFE_COUNT_OF_JOIN_GROUP_OVER: 4,
	MAX_FAVORITE_FOLDER_COUNT_OVER: 5,
	EXIT_CAFE_FAILURE_BECAUSE_TODAY_JOIN: 6,
	EXIT_CAFE_FAILURE_BECAUSE_FOUNDER: 7,
	EXIT_CAFE_FAILURE_BECAUSE_ETC: 8
};

export const getErrorString = (e) => {
	if (!e.responseJSON) throw e;

	switch (e.responseJSON.clientAwareErrorCode.code) {
		case ERROR_CODE.MAX_FAVORITE_CAFE_COUNT_OVER:
			return '즐겨찾는 카페는 최대 30개까지 등록할 수 있습니다.';
		case ERROR_CODE.EXIT_CAFE_FAILURE_BECAUSE_TODAY_JOIN:
			return '가입한 날 탈퇴할 수 없습니다.';
		case ERROR_CODE.EXIT_CAFE_FAILURE_BECAUSE_FOUNDER:
			return '운영중인 카페는 탈퇴할 수 없습니다.';
		case ERROR_CODE.NEED_TO_LOGIN:
		case ERROR_CODE.INVALID_REQUEST_PARAMETER:
		case ERROR_CODE.MAX_CAFE_COUNT_OF_JOIN_GROUP_OVER:
		case ERROR_CODE.MAX_FAVORITE_FOLDER_COUNT_OVER:
		case ERROR_CODE.EXIT_CAFE_FAILURE_BECAUSE_ETC:
		default:
			throw e;
	}
};

export default class AjaxCaller {
	static call(method, url, data, options, cb) {
		return $.ajax({
			contentType: 'application/json',
			method: method,
			url: url,
			data: data,
			...options
		}).catch((e) => {
			if (e.responseJSON) {
				if (e.responseJSON.clientAwareErrorCode.code === ERROR_CODE.NEED_TO_LOGIN)
					location.href = `https://logins.daum.net/accounts/loginform.do?url=${location.href}`;
			}

			if (cb)
				cb();
			else
				throw e;
		});
	}

	static get(url, data, options, cb) {
		return AjaxCaller.call('GET', url, data || {}, options, cb);
	}

	static post(url, data, options, cb) {
		return AjaxCaller.call('POST', url, JSON.stringify(data || {}), options, cb);
	}

	static put(url, data, options, cb) {
		return AjaxCaller.call('PUT', url, JSON.stringify(data || {}), options, cb);
	}

	static delete(url, data, options, cb) {
		return AjaxCaller.call('DELETE', url, JSON.stringify(data || {}), options, cb);
	}
}

import AjaxCaller from '../ajax-caller';

export default {
	namespaced: true,

	state() {
		return {
			suggestCafes: []
		};
	},

	getters: {
		getSuggestCafes(state) {
			return state.suggestCafes;
		}
	},

	mutations: {
		setSuggestCafes(state, cafes) {
			state.suggestCafes = cafes.map(v => ({
				cafeName: v.cafeName,
				desc: v.desc,
				homeUrl: v.homeUrl,
				thumbnail: v.thumbnail ? v.thumbnail : 'https://t1.daumcdn.net/cafe_image/cafetop/2024/default_icon.png',
				keywords: v.keywords ? v.keywords.slice(0, 3) : []
			}));
		}
	},

	actions: {
		async loadSuggestCafes({ commit }) {
			try {
				const cafes = (await AjaxCaller.get('/_c21_/api/v3/suggest/cafe')).cafes;
				commit('setSuggestCafes', cafes);
			} catch (e) {
				throw e;
			}
		}
	}
};

<template>
	<a class="link_item" :class="className" @click="onClick">
		{{name}}
		<span v-if="hasNew" class="inform_circle">새 {{name}} 도착</span>
	</a>
</template>

<script>
/**
 * 카페 메인 메뉴 서브 아이템
 *
 * @property {string} name			- 메뉴 이름
 * @property {boolean} hasNew=false	- 뉴딱지 표시 여부
 * @property {string} selectedMenu	- 전역에서 선택된 메뉴
 *
 * @fires #select					- 메뉴 선택 시 이벤트 발생
 */

export default {
	props: {
		name: String,
		hasNew: {
			type: Boolean,
			default: false
		},
		selectedMenu: String
	},
	computed: {
		className() {
			return { on: this.name === this.selectedMenu };
		}
	},

	methods: {
		onClick() {
			this.$emit('select', this.name);
		}
	}
}
</script>

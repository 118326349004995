<template>
<div>
	<div class="box_util">
		<div class="check_util">
			<label for="all" class="lab_choice">
				<span class="txt_all">전체선택</span>
				<input type="checkbox" id="all" class="inp_chk" @change="toggleSelectAll" :checked="isAllChecked">
				<div class="box_check"><span class="ico_cafenew ico_check"></span></div>
			</label>
		</div>
		<div class="info_expander">
			<a href="#none" @click="deleteCafe" class="link_expander">삭제</a>
		</div>
	</div>
	<ul class="list_mycafe list_type_check" :class="isEditMode">
		<li v-for="item in list">
			<div class="cont_wrap">
				<div class="check_util">
					<label class="lab_choice">
						<span class="screen_out">항목 선택</span>
						<input type="checkbox" :value="item.grpid" class="inp_chk" v-model="editlist">
						<div class="box_check"><span class="ico_cafenew ico_check"></span></div>
					</label>
				</div>
				<exit-cafe-item v-tiara="tiaraObj.exitCafe" :item="item"></exit-cafe-item>
			</div>
			<div class="cont_expander">
				<div class="box_tbl"><div class="inner_tbl"><a v-tiara="tiaraObj.manageArticleBtn" :href="showArticleView(item.grpid)" class="btn_expander">내 글 관리</a></div></div>
			</div>
		</li>
	</ul>
</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import ExitCafeItem from './ExitCafeItem.vue'

	export default {
		components: {
			ExitCafeItem,
		},
		props: ['list'],
		data(){
			return {
				editlist: [],
			}
		},
		computed: {
			...mapGetters({
				tiaraObj: 'getTiaraObj',
			}),
			isEditMode(){
				return {list_type_check : this.isEdit}
			},
			isAllChecked() {
				return this.editlist.length === this.list.length
			},
		},
		methods:{
			toggleSelectAll(){
				if(this.isAllChecked){
					this.editlist = []
					return false
				}
				let editlist = [];
				this.list.forEach(function(item){
					editlist.push(item.grpid)
				});
				this.editlist = editlist;
			},
			deleteCafe(){
				if(this.editlist.length < 1){
					alert("삭제할 목록을 선택해 주세요")
					return false;
				}
				if(confirm('선택한 탈퇴 카페를 삭제하시겠습니까?\n탈퇴 카페를 삭제하면 작성된 내글, 댓글을 삭제할 수 없습니다.'))
					this.$emit('sendDel', this.editlist)
			},
			showArticleView(grpid){
				return "/_c21_/exit_cafe?grpid=" + grpid
			},
		},
	}
</script>

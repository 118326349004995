var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap_paging_number" }, [
    _c("span", { staticClass: "screen_out" }, [_vm._v("페이지 위치정보")]),
    _vm._v(" "),
    _c("div", { staticClass: "inner_paging_number paging_prev paging_next" }, [
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPrev,
              expression: "showPrev",
            },
          ],
          staticClass: "btn_item btn_prev",
          attrs: { type: "button" },
          on: { click: _vm.getPrevPage },
        },
        [
          _c("span", { staticClass: "ico_cafenew" }, [
            _vm._v("이전 목록으로 이동"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "ol",
        { staticClass: "list_paging" },
        _vm._l(_vm.range(_vm.start, _vm.end), function (i) {
          return _c("li", { class: { on: _vm.isCurrent(i) } }, [
            _c(
              "a",
              {
                staticClass: "link_num",
                attrs: { role: "button", href: "javascript:" },
                on: {
                  click: function ($event) {
                    return _vm.pageChange(i)
                  },
                },
              },
              [
                _vm.isCurrent(i)
                  ? _c("span", { staticClass: "screen_out" }, [
                      _vm._v("현재 페이지"),
                    ])
                  : _vm._e(),
                _c("span", { staticClass: "num_item" }, [_vm._v(_vm._s(i))]),
              ]
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showNext,
              expression: "showNext",
            },
          ],
          staticClass: "btn_item btn_next",
          attrs: { type: "button" },
          on: { click: _vm.getNextPage },
        },
        [
          _c("span", { staticClass: "ico_cafenew" }, [
            _vm._v("다음 목록으로 이동"),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
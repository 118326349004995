var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "link_item",
      class: _vm.className,
      on: { click: _vm.onClick },
    },
    [
      _vm._v("\n\t" + _vm._s(_vm.name) + "\n\t"),
      _vm.hasNew
        ? _c("span", { staticClass: "inform_circle" }, [
            _vm._v("새 " + _vm._s(_vm.name) + " 도착"),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div class="wrap_paging_number">
		<span class="screen_out">페이지 위치정보</span>
		<div class="inner_paging_number paging_prev paging_next">
			<button v-show="showPrev" @click="getPrevPage" type="button" class="btn_item btn_prev"><span class="ico_cafenew">이전 목록으로 이동</span></button>
			<ol class="list_paging">
				<li v-for="i in range(start, end)" :class="{on: (isCurrent(i))}">
					<a role="button" href="javascript:" @click="pageChange(i)" class="link_num"><span v-if="isCurrent(i)" class="screen_out">현재 페이지</span><span class="num_item">{{i}}</span></a>
				</li>
			</ol>
			<button v-show="showNext" @click="getNextPage" type="button" class="btn_item btn_next"><span class="ico_cafenew">다음 목록으로 이동</span></button>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			totalPage: {
				type: Number
			},
			startPage: {
				type: Number,
				default: 1
			},
			displayPageCount: {
				type: Number,
				default: 9
			}
		},
		data() {
			return {
				currentPage: 1
			}
		},
		computed: {
			start() {
				return Math.max(this.currentPage - Math.floor((this.displayPageCount - 1) / 2), 1);
			},
			end() {
				return Math.min(this.totalPage, this.start + this.displayPageCount - 1);
			},
			showPrev() {
				return this.currentPage > this.start;
			},
			showNext() {
				return this.currentPage < this.end;
			}
		},
		methods: {
			range(start, end) {
				var range = [];
				for (let i = start; i <= end; i++) {
					range.push(i);
				}
				return range;
			},
			isCurrent(i) {
				return this.currentPage === i;
			},
			pageChange(page) {
				this.currentPage = page;
				this.$emit('pageChange', this.currentPage);
			},
			getPrevPage() {
				this.currentPage--;
				this.$emit('pageChange', this.currentPage);
			},
			getNextPage() {
				this.currentPage++;
				this.$emit('pageChange', this.currentPage);
			}
		},
		created() {
			this.currentPage = this.startPage;
		}
	}
</script>


export default {
	install(Vue, options) {
		Vue.dropdownList = [];

		Vue.directive('dropdown', {
			bind(el, binding) {
				if (Vue.dropdownList.find(v => v.name === el.id)) {
					console.warn('the name of dropdown should be unique; please use another name');
					return;
				}

				Vue.dropdownList.push({
					name: el.id,
					close: binding.value
				});
			},

			update(el) {
				if (el.className.indexOf('opt_open') > -1) {
					Vue.dropdownList
						.filter(v => v.name !== el.id)
						.forEach(v => {
							v.close()
						});
				}
			}
		});
	}
}

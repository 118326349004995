import { render, staticRenderFns } from "./CafeMenu.vue?vue&type=template&id=22e802c0&scoped=true"
import script from "./CafeMenu.vue?vue&type=script&lang=js"
export * from "./CafeMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22e802c0",
  null
  
)

export default component.exports
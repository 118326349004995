<template>
	<div class="cont_mycafe">
		<h4 class="screen_out">{{ name }}</h4>
		<div class="box_util">
			<slot name="header"></slot>
		</div>
		<ul class="list_mycafe" :class="listClass">
			<slot></slot>
		</ul>
		<slot name="footer"></slot>
	</div>
</template>

<script>
/**
 * 카페 테이블/리스트 컴포넌트
 *
 * @property {string} name		- 리스트 이름(보이스오버용)
 * @property {string} listClass	- ul 클래스 바인딩
 */
export default {
	props: ['listClass', 'name']
}
</script>

<template>
	<li><!-- 기간 만료인경우 type_finish 클래스 추가 -->
		<div class="cont_wrap">
			<a v-tiara="tiaraObj.backupCafe" :href="item.link" target="_blank" class="thumb_info_type">
				<div class="wrap_info">
					<div class="box_tbl">
						<div class="inner_tbl">
							<strong class="tit_info">{{item.cafeName}}</strong>
							<div v-if="item.closeDate" class="additional_info"><span class="txt_date">페쇄 예고일 {{item.closeDate}}</span></div>
						</div>
					</div>
				</div>
			</a>
		</div>
		<div class="cont_expander">
			<div class="box_tbl">
				<div v-if="item.applyLink" class="inner_tbl"><a v-tiara="tiaraObj.backupApplyBtn" @click="applyBackup" target="_blank" class="btn_expander">백업 신청하기</a></div>
				<div v-else-if="item.downLink" class="inner_tbl">
					<span class="txt_expander">{{item.expDate}} 만료</span>
					<a v-tiara="tiaraObj.backupDownloadBtn" :href="item.downLink" class="btn_expander ml_type">백업 파일 다운로드</a>
				</div>
				<div v-else-if="item.expired" class="inner_tbl">
					<span class="txt_expander txt_color">다운로드 기간만료</span>
				</div>
				<div v-else-if="item.endDate" class="inner_tbl">
					<span class="txt_expander ml_type">백업 신청완료</span>
				</div>
			</div>
		</div>
	</li>
</template>

<script>
	import { mapGetters } from 'vuex';
	import moment from 'moment';

	export default {
		props: ['item'],
		computed: {
			...mapGetters({
				tiaraObj: 'getTiaraObj',
			}),
			convertedDate() {
				return moment(this.item.date, 'YYYY.MM.DD').format('YYYY.MM.DD.');		//datefns
			}
		},
		methods: {
			applyBackup(){
				this.$emit('applyBackup', this.item.grpId)
			}
		}
	}
</script>

<template>
	<div id="cSub">
		<h2 class="screen_out">{{ name }}</h2>
		<div :class="wrapClass">
			<div class="snb_cafe">
				<h3 class="screen_out">{{ name }} 서브메뉴</h3>

				<div class="inner_snb">
					<ul id="menu-slot" class="list_snb">
						<cafe-menu-item v-for="menu in mainMenu"
										:name="menu"
										:selected-menu="selectedMenu"
										@select="onMenuSelect"></cafe-menu-item>
					</ul>
					<div class="wrap_inform" v-if="subMenu.length">
						<cafe-menu-sub-item v-for="menu in subMenu"
											:name="menu.name"
											:has-new="menu.isNew"
											:selected-menu="selectedMenu"
											@select="onMenuSelect"></cafe-menu-sub-item>
					</div>
				</div>
				<template v-if="hasButtons">
					<div class="wrap_btn wrap_prev" :class="prevBtnClass">
						<button class="btn_snb" @click="onShift"><span class="ico_cafenew ico_prev">이전 메뉴</span></button>
					</div>
					<div class="wrap_btn wrap_next" :class="nextBtnClass">
						<button class="btn_snb" @click="onShift"><span class="ico_cafenew ico_next">다음 메뉴</span></button>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery';
import CafeMenuItem from './CafeMenuItem.vue';
import CafeMenuSubItem from './CafeMenuSubItem.vue';

/**
 * 카페 메인 메뉴
 *
 * @property {string} name					- 메뉴 이름
 * @property {boolean} hasButtons=false		- 메뉴 좌우 이동 버튼 표시 여부
 * @property {array} menuList				- 메뉴 리스트
 * @property {integer} initial-index=-1		- 선택된 메뉴 초기값
 *
 * @fires #select							- 메뉴 선택 시 이벤트 발생
 */
export default {
	components: {
		CafeMenuItem, CafeMenuSubItem
	},

	props: {
		name: String,
		hasButtons: {
			type: Boolean,
			default: false
		},
		menuList: Array,
		initialIndex: {
			type: Number,
			default: -1
		},
		wrapClass: String
	},
	data() {
		return {
			selected: 0,
			shifted: false,
		}
	},
	computed: {
		menuList_() {
			return this.menuList.map(v => typeof v === 'string' ? { name: v } : v);
		},

		selectedMenu() {
			return this.menuList_[this.selected].name;
		},
		prevBtnClass() {
			return { btn_off: !this.shifted };
		},
		nextBtnClass() {
			return { btn_off: this.shifted };
		},

		mainMenu() {
			return this.menuList_
				.filter(v => typeof v === 'string' || !v.isSub)
				.map(v => typeof v === 'string' ? v : v.name);
		},

		subMenu() {
			return this.menuList_
				.filter(v => v.isSub)
				.map(v => ({ name: v.name, isSub: v.isSub, isNew: v.isNew || false }));
		}
	},

	watch: {
		menuList() {
			this.selected = 0;
			this.$emit('select', 0);
		},
		initialIndex() {
			// FIXME: ajax 때문에 초기값이 바뀌어서 넣은 비상용 코드. 더 좋은 방법이 있을지 찾아볼 것.
			this.selected = this.initialIndex;
		}
	},

	methods: {
		onMenuSelect(menu) {
			this.selected = this.menuList_.findIndex(v => v.name === menu);
			this.$emit('select', this.selected);
		},
		onShift() {
			const beforeShift = this.shifted;
			this.shifted = !this.shifted;

			const menuSlot = $('#menu-slot');
			const shiftLength = menuSlot.width() - $('#cSub').find('.snb_cafe').width() + 30;
			menuSlot.animate({
				left: beforeShift ? `+=${shiftLength}` : `-=${shiftLength}`
			}, 300);
		}
	},

	created() {
		if (this.menuList === undefined)
			throw new Error('`menuList` is undefined');
		if (this.menuList.length <= this.initialIndex)
			throw new Error('`initialIndex` cannot be greater than or equal to the length of `menuList`');
		this.menuList.forEach(v => {
			if (typeof v !== 'string' && v.name === undefined)
				throw new Error('Each element of `menuList` only can be string or the object having `name`');
		});

		this.selected = this.initialIndex === -1 ? 0 : this.initialIndex;
	}
}
</script>

<style scoped>

</style>

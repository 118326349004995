import moment from 'moment';

export default {
	install(Vue, options) {
		Vue.realtimeList = [];
		Vue.timer = setInterval(() => {
			Vue.realtimeList.forEach(v => {
				v();
			})
		}, 1000);

		Vue.directive('realtime', {
			bind(el, binding, vnode) {
				Vue.realtimeList.push(vnode.context.$_updateNow);
			}
		});

		Vue.mixin({
			data() {
				return {
					formats_: {
						brandNew: '방금 전',
						beforeOneDay: 'HH:mm',
						defaultFormat: 'YY.MM.DD.'
					},
					now$: moment()
				}
			},

			methods: {
				$_format(timeText) {
					const diffMinutes = this.now$.diff(timeText, 'minutes');
					const timeObj = moment(timeText);

					if (diffMinutes < 1) return this.formats_.brandNew;
					if (diffMinutes < 60 * 3) {
						if (diffMinutes < 60) return `${diffMinutes}분전`;
						if (diffMinutes === 60 || diffMinutes === 120) return `${Math.floor(diffMinutes/60)}시간전`;
						return `${Math.floor(diffMinutes/60)}시간 ${diffMinutes%60}분전`;
					}
					if (diffMinutes < 60 * 24 && this.now$.date() === timeObj.date())
						return timeObj.format(this.formats_.beforeOneDay);
					return timeObj.format(this.formats_.defaultFormat);
				},
				$_updateNow() {
					this.now$ = moment();
				}
			},

			beforeDestroy() {
				clearInterval(Vue.timer);
			}
		});
	}
}

<template>
	<li :class="className"><a class="link_snb" @click="onClick"><span class="txt_item">{{name}}</span></a></li>
</template>

<script>
/**
 * 카페 메인 메뉴 아이템
 *
 * @property {string} name				- 표시될 이름
 * @property {string} selectedMenu		- 전역에서 선택된 메뉴
 *
 * @fires #select						- 메뉴 선택 시 이벤트 발생
 */
export default {
	props: ['name', 'selectedMenu'],
	computed: {
		className() {
			return { on: this.selectedMenu === this.name };
		}
	},

	methods: {
		onClick() {
			this.$emit('select', this.name);
		}
	}
}
</script>

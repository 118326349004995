<template>
		<a :href="item.link" target="_blank" class="thumb_info_type">
		<div class="wrap_info">
			<div class="box_tbl">
				<div class="inner_tbl">
					<strong class="tit_info">{{item.cafeName}}</strong>
					<div class="additional_info">
						<span class="txt_date">탈퇴일&nbsp; {{convertedDate}}</span>
					</div>
				</div>
			</div>
		</div>
	</a>
</template>

<script>
	import moment from 'moment';
	export default {
		props: ['item'],
		computed: {
			convertedDate() {
				return moment(this.item.memberSecedeDay, 'YYYY-MM-DD').format('YYYY.MM.DD.');
			}
		},
		methods:{
		}
	}
</script>

<template>
	<li :class="className"><a class="link_opt" @click="onClick">{{ name }}</a></li>
</template>

<script>
/**
 * 드롭다운 아이템
 *
 * @property {string} name		- 옵션 이름
 * @property {boolean} selected	- 해당 옵션이 선택되었는지 표시
 */
export default {
	props: ['name', 'selected'],

	computed: {
		className() {
			return { on: this.selected };
		}
	},

	methods: {
		onClick() {
			this.$emit('select', this.name);
		}
	}
}
</script>

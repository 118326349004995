export default {
	install(Vue, options) {
		if (typeof TiaraTracker === 'undefined') return;
		const tiaraInstance = TiaraTracker.getInstance();

		const trackPage = () => {
			tiaraInstance.setSvcDomain('cafe.daum.net')
				.setSection('top')
				.setPage(options.pageName)
				.setSessionTimeout('1800')
				.setDeployment(IS_DEV ? 'dev' : 'production')
				.setIgnoreQueryParams(['ts', 'dummy'])
				.trackPage(options.pageName)
				.customProps(options.customProps || {})
				.track();
		};

		const trackEvent = ({layer, desc, url = '', customProps = {}}) => {
			const clickObject = {
				layer1: layer,
				layer2: '',
				layer3: '',
				click_url: url.slice(0, 255),
				copy: '',
				posx: 0,
				posy: 0
			};

			tiaraInstance
				.setSection('top')
				.setPage(options.pageName)
				.trackEvent(`카페탑>${options.pageNameDesc}>${desc}`)
				.click(clickObject)
				.customProps(customProps)
				.track();
		};

		const trackUserActionEvent = ({page, pageName, layer, layerName, url, customProps}) => {
			const clickObject = {
				layer1: layer,
				layer2: '',
				layer3: '',
				click_url: url.slice(0, 255),
				copy: '',
				posx: 0,
				posy: 0
			};

			tiaraInstance
				.setSection('user_action')
				.setPage(page)
				.trackEvent(`유저액션_${pageName}_${layerName}`)
				.click(clickObject)
				.customProps(customProps)
				.track();
		};

		const trackViewImpContents = (viewImpContents) => {
			if (viewImpContents.length === 0) {
				return;
			}

			const {page, pageName} = options.userAction;

			tiaraInstance
				.setSection('user_action')
				.setPage(page)
				.trackViewImp('유저액션_' + pageName + '_게시글피드')
				.viewImpContents(viewImpContents)
				.track();
		};

		Vue.prototype.$trackEvent = trackEvent;
		Vue.prototype.$trackPage = trackPage;

		const viewImpContentMap = {};
		const viewImpContents = [];
		const intersectionObserver = IntersectionObserver !== undefined ? new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (!entry.isIntersecting) {
					return;
				}

				observer.unobserve(entry.target);

				const id = entry.target.getAttribute('data-view-imp-id');
				viewImpContents.push(viewImpContentMap[id]);
			});
		}) : null;

		window.addEventListener('beforeunload', () => {
			trackViewImpContents(viewImpContents);
		});

		Vue.directive('tiara', {
			bind(el, binding) {
				el.addEventListener('click', (e) => {
					trackEvent({
						layer: binding.value.layer,
						desc: binding.value.desc,
						url: e.currentTarget.href || '',
						customProps: binding.value.customProps || {}
					});
				});
			}
		});

		Vue.directive('tiara-user-action', {
			bind(el, binding) {
				el.addEventListener('click', (e) => {
					trackUserActionEvent({
						...binding.value,
						url: e.currentTarget.href || ''
					});
				});
			}
		});

		Vue.directive('tiara-view-imp', {
			bind(el, binding) {
				if (!intersectionObserver) {
					return;
				}

				const {id, viewImpContent} = binding.value;
				viewImpContentMap[id] = viewImpContent;
				intersectionObserver.observe(el);
			}
		});
	}
}
